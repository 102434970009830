import React, { useState, useCallback, useRef, ChangeEvent, useEffect } from 'react';
import { SimpleFormProps, useNotify, useDataProvider, useQuery } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { IBanner, IMutationError, ICountry } from './../../interfaces/model.interfaces';
import { Formik, FormikProps } from 'formik';
import {
    Button,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    FormGroup,
} from '@material-ui/core';
import { appConfig } from './../../config/app.config';
import { serialize } from 'object-to-formdata';
import { apiRequestProvider, extractErrorMessage } from './../../providers/api-request.provider';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    avatarImg: {
        maxWidth: 300,
        marginBottom: theme.spacing(1),
    },
    avatarBtn: {
        display: 'none',
    },
    avatarName: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const CreateForm: React.FC<SimpleFormProps> = (
    props: SimpleFormProps,
) => {

    const { history } = props;

    const [countryList, setCountryList] = useState<ICountry[]>([]);

    const [initialValues] = useState<IBanner>({
        picture: '',
        countries: [],
    });

    const validationSchema = yup.object().shape({
        countries: yup.array()
            .test('isChosen', 'Countries is required field', (data) => !!data?.length),
    });

    const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const classes = useStyles();

    const { data: countries } = useQuery({
        type: 'getList',
        resource: 'countries',
        payload: {
            pagination: {
                page: 1,
                perPage: 200,
            },
        },
    });

    useEffect(() => {

        if (countries) {
            const list = countries.filter((country: ICountry) => {
                return ['Philippines', 'Malaysia', 'Singapore'].includes(country.name);
            });

            setCountryList(list);
        }
        
    }, [countries]);

    const onSuccess = useCallback(() => {
        notify('Banner created successful', 'success');
        history.push('/banners');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback(async(data: IBanner) => {

        if (!data.picture) {
            notify('Please choose a banner picture', 'error');

            return false;
        }

        if (data.picture) {
            const formData = serialize(data);

            await apiRequestProvider({
                url: '/banners',
                method: 'POST',
                data: formData,
                onSuccess,
                onFailure,
            });
        } else {
            dataProvider.create('banners', {
                data,
            }, {
                onSuccess,
                onFailure,
            });
        }

    }, [notify, onSuccess, onFailure, dataProvider]);

    const handleChangePicture = useCallback(async(event: ChangeEvent<any>, props: FormikProps<IBanner>) => {
        event.stopPropagation();
        event.preventDefault();

        const file: File = event.target.files[0];

        props.setFieldValue('picture', file);
    }, []);

    const onClickPicture = useCallback(() => {
        if (!fileInput.current) {
            return;
        }

        fileInput.current.click();
    }, [fileInput]);


    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IBanner>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>Create banner</h2>
                                </Grid>
                                <Grid item sm={12}>
                                    <form
                                        onSubmit={props.handleSubmit}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item md={12} sm={12}>
                                                <FormGroup>
                                                    <FormControl
                                                        error={Boolean(props.errors.countries)}
                                                        className={classes.formControl}
                                                    >
                                                        <InputLabel
                                                            shrink={!!props.values.countries?.length}
                                                            id="countries-select-label"
                                                        >
                                                            Countries
                                                        </InputLabel>
                                                        <Select
                                                            fullWidth
                                                            label="Countries"
                                                            id="countries-select-label"
                                                            labelId="countries-select"
                                                            name="countries"
                                                            value={props.values.countries}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            multiple
                                                            displayEmpty
                                                        >
                                                            {countryList && countryList.map((country: ICountry) => (
                                                                <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {props.errors?.countries &&
                                                            <FormHelperText>
                                                                {props.errors.countries}
                                                            </FormHelperText>
                                                        }
                                                    </FormControl>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={12} sm={12}>
                                                {props.values.picture && typeof props.values.picture === 'string' &&
                                                    <div>
                                                        <img
                                                            className={classes.avatarImg}
                                                            src={`${appConfig.apiEndpoint}/${props.values.picture}`}
                                                        />
                                                    </div>
                                                }

                                                <input
                                                    className={classes.avatarBtn}
                                                    type="file"
                                                    name="avatar"
                                                    ref={fileInput}
                                                    accept="image/jpeg, image/png"
                                                    // eslint-disable-next-line react/jsx-no-bind
                                                    onChange={(e) => handleChangePicture(e, props)}
                                                />
                                                <Button
                                                    size="large"
                                                    onClick={onClickPicture}
                                                    color="primary"
                                                    variant="outlined"
                                                >
                                                    Upload Picture
                                                </Button>
                                                <>
                                                    {props.values.picture &&
                                                        <p
                                                            className={classes.avatarName}
                                                        >
                                                            {props.values.picture instanceof File ? props.values.picture.name : ''}
                                                        </p>
                                                    }
                                                </>
                                            </Grid>
                                            <Grid item md={12} sm={12}>
                                                <Button
                                                    type="submit"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};